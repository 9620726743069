





import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    ORegistrationForm: () => import("@/components/organisms/globalTools/registrationForm/o-registration-form.vue"),
  }
});
